<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="80"
    class="flex flex-col sm:mx-6 mt-6 py-6 px-6 sm:px-16"
  >
    <AssetTitle title="Marketplace" />
    <div v-if="!isUnderVerfication" class="flex flex-wrap justify-center">
      <TokenListCard v-for="token in tokens" :key="token.id" :token="token" />
    </div>
    <Loader
      v-if="isLoading || isUnderVerfication"
      :classes="'w-24 h-24 mx-auto'"
    />
  </div>
</template>

<script>
import * as api from '@/api';
import AssetTitle from '@/components/AssetTitle';
import TokenListCard from '@/components/cards/TokenListCard';
import Loader from '@/components/Loader';

import { emitError, emitSucces } from '@/utils/alerts';
import eventBus from '@/utils/eventBus';
import redirect from '@/utils/redirect';

export default {
  name: 'Home',
  components: {
    AssetTitle,
    TokenListCard,
    Loader,
  },
  data() {
    return {
      tokens: [],
      busy: false,
      lastPage: 0,
      hasMore: true,
      isLoading: true,
      isUnderVerfication: true,
    };
  },

  async mounted() {
    const { token, state } = this.$route.query;
    if (token && state) {
      await this.verify(token, state);
      this.isUnderVerfication = false;

      return;
    }
    this.isUnderVerfication = false;
    this.loadMore();
  },
  methods: {
    async loadMore() {
      // pagination
      if (this.busy || !this.hasMore) return;
      this.busy = true;
      this.isLoading = true;

      const { count, results } = await api.get('tokens', {
        state: 'FOR_SALE',
        page: this.lastPage + 1,
      });

      this.tokens = this.tokens.concat(results);
      this.hasMore = count > this.tokens.length;
      this.isLoading = false;

      this.busy = false;
      this.lastPage++;
    },
    async verify(token, state) {
      try {
        const { authsec_url, confirmation_id } = await api.post(
          'verify-email',
          {
            token,
            state,
          },
        );
        if (authsec_url && confirmation_id) {
          // Email Verification
          eventBus.$emit('open-approve-account', authsec_url, confirmation_id);
          this.loadMore();
        } else {
          // Login OTP
          emitSucces('Login successful.');
          const userData = await api.get('user');
          await this.$store.dispatch('user/LOGIN_USER', userData);
          redirect({ name: 'Home' });
        }
      } catch (e) {
        let msg = 'Email confirmation error';
        if (e.response.data) {
          msg = (e.response.data.non_field_errors || []).join(' ');
          if (msg === 'This user is already verified.') {
            emitSucces('Email already verified.');
            redirect({ name: 'Home' });
            return;
          } else if (msg === 'The email is not confirmed.') {
            emitError(
              'Your confirmation <span class="font-bold">link is expired</span>, please try to sign in again to get a new one, in case you can\'t confirm your email again, please contact us.',
            );
            return;
          }
        }
        emitError(msg);
      }
    },
  },
};
</script>
<style scoped>
.tokenCard:first-child {
  margin-right: auto;
}

.tokenCard:last-child {
  margin-left: auto;
}
</style>
